// :: Dependencies
import {
  auth,
  firestore,
  database,
  databaseInstance,
  deleteInitializeApp
} from "@/config/firebase";
import { showMessage } from "@/services/MessageService";
import HandlingError from "@/services/HandlingErrorCodes";
import router from "@/config/router";
import moment from "moment";

let databaseMetadataRef = null;
let callbackMetadata = null;

export default {
  /** loginWithEmailAndPassword Function
   * @param {Object} data - Signs a user with email and password
   * Doc: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
   **/
  async loginWithEmailAndPassword({ commit, dispatch }, data) {
    try {
      const { email, password } = data;
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      const tokenResultData = await auth.currentUser.getIdTokenResult();
      const customClaims = tokenResultData.claims;
      await dispatch("getComponentsUser");
      commit("userSave", { user, customClaims });
      const redirectToBusinessSetup = await dispatch(
        "checkBusinessData",
        customClaims
      );
      if (redirectToBusinessSetup) router.push({ name: "businessSetup" });
      else {
        const businessDatabase = await dispatch(
          "business/getBusinessDatabase",
          customClaims.business_id,
          { root: true }
        );
        await databaseInstance(businessDatabase.databaseUrl, {
          email,
          password
        }); // init database in first login
        dispatch("updateOnlineStatusUser", "online"); // Manual change status users in app
        router.push({ name: "home" });
      }
      commit("setLoginLoader", true);
    } catch (error) {
      HandlingError(error.code);
    }
  },
  /** signOut Function
   * Performs user logout
   * Doc: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sign-out
   **/
  async signOut({ commit, dispatch }) {
    try {
      await auth.signOut();
      await deleteInitializeApp(); // :: Delete init customer firebase app
      commit("userLogout");
      // :: Reset localstorage state
      dispatch("user/resetState", null, { root: true });
      dispatch("pointSale/resetState", null, { root: true });
      dispatch("business/resetState", null, { root: true });
      dispatch("products/resetState", null, { root: true });
      router.push({ name: "login" });
    } catch (error) {
      HandlingError(error.code);
    }
  },
  async geUserData({ state, commit, getters }) {
    const userUid = state.data.uid;
    const clientId = state.data.customClaims.client_id;
    let userdata = null;
    try {
      if (getters.isOwner) {
        userdata = await firestore("customers")
          .doc(userUid)
          .get();
      } else {
        userdata = await firestore("customers")
          .doc(clientId)
          .collection("users")
          .doc(userUid)
          .get();
      }
      commit("userInfoData", userdata.data());
    } catch (error) {
      HandlingError(error.code);
    }
  },
  /** Get the currently signed-in user in the app
   * Doc: https://firebase.google.com/docs/auth/web/manage-users?hl=es-419#get_the_currently_signed-in_user
   **/
  async getAuthState({ commit }) {
    auth.onAuthStateChanged(async user => {
      if (callbackMetadata) {
        databaseMetadataRef.off("value");
      }
      if (user) {
        const tokenResultData = await auth.currentUser.getIdTokenResult();
        const customClaims = tokenResultData.claims;
        commit("userSave", { user, customClaims });
        databaseMetadataRef = database(`metadata/${user.uid}`);
        callbackMetadata = async () => {
          await user.getIdToken(true);
          const tokenResultData = await auth.currentUser.getIdTokenResult();
          const customClaims = tokenResultData.claims;
          commit("userSave", { user, customClaims });
        };
        databaseMetadataRef.on("value", callbackMetadata);
      } else {
        commit("userLogout");
      }
    });
  },
  /**
   *
   * Doc: Custom callable function https://firebase.google.com/docs/functions/callable#call_the_function
   * @param {string} email - email to send message
   */
  async resetPasswordAction(state, email) {
    try {
      await auth.sendPasswordResetEmail(email);
      showMessage({
        message: "Hemos enviado un correo a " + email + " de recuperación",
        color: "success"
      });
      router.push({ name: "login" });
    } catch (error) {
      HandlingError(error.code);
    }
  },
  /**
   * Change status of user (online or offline)
   * @param {Object} param0 - Params of store (state and commit)
   */
  onDesconnect({ state, commit }) {
    const userStatusDatabaseRef = database(`user_status/${state.data.uid}`);
    const isOfflineForDatabase = {
      state: "offline",
      last_changed: moment().unix()
    };
    const isOnlineForDatabase = {
      state: "online",
      last_changed: moment().unix()
    };
    database(".info/connected").on("value", function(snapshot) {
      commit("userStatus", snapshot.val());
      if (snapshot.val() == false) return;
      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function() {
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
  },
  /**
   * Change status of user by online when user login
   * @param {Object} param0 - Params of store (state and commit)
   */
  updateOnlineStatusUser({ state, commit }, status) {
    const userStatusDatabaseRef = database(`user_status/${state.data.uid}`);
    let isOnlineForDatabase = null;
    if (status === "online") {
      isOnlineForDatabase = {
        state: "online",
        last_changed: moment().unix()
      };
    }
    if (status === "offline") {
      isOnlineForDatabase = {
        state: "offline",
        last_changed: moment().unix()
      };
    }
    if (isOnlineForDatabase) {
      commit("userStatus", true);
      userStatusDatabaseRef.set(isOnlineForDatabase);
    }
  },
  /**
   * Prevent business data it's filled
   * @param {Vuex} param0 - Commit business status
   * @param {Object} customClaims - Customs clains user
   */
  async checkBusinessData({ commit, dispatch }, customClaims) {
    const bussiness = await firestore("business")
      .doc(customClaims.business_id)
      .get();
    if (bussiness.data().status != "PENDING") {
      commit("setBussinessStatus", false);
      await dispatch("business/getBranchOfficeData", null, { root: true });
      return false;
    } else if (customClaims.admin) {
      commit("setBussinessStatus", true);
      return true;
    }
  },
  /**
   * Get status of components to show the user
   * @param {Vuex} param0 - Vuex commit
   * @param {String} uid - UID user
   */
  async getComponentsUser({ commit, state, getters }) {
    const userUid = state.data.uid;
    const clientId = state.data.customClaims.client_id;
    let userData = null;
    if (getters.isOwner) {
      userData = await firestore("customers")
        .doc(userUid)
        .get();
    } else {
      userData = await firestore("customers")
        .doc(clientId)
        .collection("users")
        .doc(userUid)
        .get();
    }

    const components = userData.data().components;
    commit("setComponentsUser", components);
  },
  /**
   * Change the values of components views
   * @param {Vuex} param0 - state and commit of Vuex
   * @param {String} keyComponent - key of object component
   */
  async setComponentUser({ state, commit, getters }, keyComponent) {
    const userUid = state.data.uid;
    const clientId = state.data.customClaims.client_id;
    const components = state.components;
    components[keyComponent] = false;
    commit("setComponentsUser", components);
    if (getters.isOwner) {
      await firestore("customers")
        .doc(userUid)
        .update({
          components: components
        });
    } else {
      await firestore("customers")
        .doc(clientId)
        .collection("users")
        .doc(userUid)
        .update({
          components: components
        });
    }
  },
  async getAllUsers({ state }) {
    const clientId = state.data.customClaims.client_id;
    const users = [];
    const usersData = await firestore("customers")
      .doc(clientId)
      .collection("users")
      .get();
    usersData.forEach(doc => {
      users.push({
        photo: doc.data().photoURL ? doc.data().photoURL : false,
        first_name: doc.data().first_name,
        last_name: doc.data().last_name,
        role: doc.data().employee ? "Colaborador" : "Administrador",
        email: doc.data().email,
        uid: doc.data().uid,
        status: false
      });
    });
    return users;
  },
  resetState({ commit }) {
    commit("resetState");
  }
};
