<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <!-- Header -->
    <app-header v-if="!isLargeAndUp && logged && loginLoader"></app-header>
    <!-- End Header -->

    <!-- Body -->
    <app-view></app-view>
    <!-- End Body -->

    <!-- Snackbar notifications -->
    <app-snackbar-notification></app-snackbar-notification>
    <!-- End nackbar notifications -->

    <!-- SideBar -->
    <app-side-bar v-if="logged && !bussinessInfo"> </app-side-bar>
    <!-- End SideBar -->

    <!-- Loader -->
    <app-loader></app-loader>
    <!-- End Loader -->

    <!-- App Update -->
    <v-snackbar bottom :value="updateExists" :timeout="-1" dark color="#f18d1d">
      Hay contenido nuevo en la app
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="refreshApp" color="secondary" small>
          Descargar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import View from "@/components/layout/view";
import Header from "@/components/layout/Header";
import Drawe from "@/components/layout/Drawer";
import Loader from "@/components/layout/Loader";
import SnackbarNotification from "@/components/layout/SnackbarNotification";
import { databaseInstance } from "@/config/firebase";
import updatePWA from "./config/mixins/updatePWA";

export default {
  name: "App",
  components: {
    AppView: View,
    AppHeader: Header,
    AppSideBar: Drawe,
    AppLoader: Loader,
    AppSnackbarNotification: SnackbarNotification
  },
  mixins: [updatePWA],
  computed: {
    ...mapState("user", ["logged", "bussinessInfo", "loginLoader"]),
    ...mapGetters("settings", ["getTheme"]),
    ...mapGetters("business", ["getBusinessDatabaseURL"]),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    isLargeAndUp() {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  },
  methods: {
    ...mapActions("user", ["onDesconnect", "getAuthState"]),
    checkCustomerDataValidation() {
      if (this.bussinessInfo) {
        this.$router.push({ name: "businessSetup" });
        return;
      }
    }
  },
  created() {
    const businessDatabase = this.getBusinessDatabaseURL;
    if (businessDatabase) databaseInstance(businessDatabase, null);
    this.getAuthState();
    this.checkCustomerDataValidation();
  },
  mounted() {
    this.$vuetify.theme.dark = this.getTheme;
    if (this.logged) {
      this.onDesconnect();
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

.v-application {
  font-family: "Poppins", sans-serif !important;
}
.v-application .text-h1,
.v-application .text-h2,
.v-application .text-h3,
.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6,
.v-application .text-subtitle-1,
.v-application .text-subtitle-2,
.v-application .text-body-1,
.v-application .text-body-2,
.v-application .v-btn,
.v-application .caption,
.v-application .overline {
  font-family: "Poppins", sans-serif !important;
}
p {
  font-size: 0.9rem;
}

/* Start Apexchart styles */
.apexcharts-tooltip {
  color: black !important;
}
/* End Apexchart styles */

/* General styles */
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
