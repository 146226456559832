import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

// :: Sub-Modules
import post from "./post";

export default {
  namespaced: true,
  state: state(),
  getters,
  mutations,
  actions,
  modules: {
    post
  }
};
