import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";
import { theme } from "@/config/globals";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: theme.themes.light.primary,
        secondary: theme.themes.light.secondary,
        extra: theme.themes.light.extra,
        accent: theme.themes.light.accent,
        error: theme.themes.light.error,
        info: theme.themes.light.info,
        success: theme.themes.light.success,
        warning: theme.themes.light.warning,
        background: theme.themes.light.background,
        card_background: theme.themes.light.card_background,
        light_black: theme.themes.light.light_black
      },
      dark: {
        primary: theme.themes.dark.primary,
        secondary: theme.themes.dark.secondary,
        extra: theme.themes.dark.extra,
        accent: theme.themes.dark.accent,
        error: theme.themes.dark.error,
        info: theme.themes.dark.info,
        success: theme.themes.dark.success,
        warning: theme.themes.dark.warning,
        background: theme.themes.dark.background,
        card_background: theme.themes.dark.card_background,
        light_black: theme.themes.dark.light_black
      }
    }
  },
  lang: {
    locales: { es },
    current: "es"
  },
  icons: {
    iconfont: "md"
  }
});
