export default {
  getRootCategories: ({ categories }) => {
    const rootCategories = categories.map(categorie => {
      return {
        id: categorie.id,
        name: categorie.name,
        picture: categorie.picture
      };
    });
    return rootCategories;
  },
  getSubCategories: ({ categories }) => id => {
    const indexCategorie = categories.findIndex(el => el.id === id);
    const rootCategories = categories[indexCategorie].children_categories.map(
      categorie => {
        return {
          id: categorie.id,
          name: categorie.name,
          picture: categorie.picture
        };
      }
    );
    return rootCategories;
  },
  getLastCategorySelected({ currentCategory }) {
    return currentCategory;
  }
};
