<template>
  <v-app-bar app height="45" elevate-on-scroll>
    <v-app-bar-nav-icon
      @click.stop="toogleDrawer"
      v-if="!$vuetify.breakpoint.lgAndUp && !bussinessInfo"
    ></v-app-bar-nav-icon>
    <div class="absolute-branch">
      <router-link to="/" v-if="!bussinessInfo">
        <img
          v-if="$vuetify.theme.dark"
          src="../../assets/ssencial-logo-white.svg"
          height="30px"
          alt="ssencial"
          class="mt-2"
        />
        <img
          v-else
          src="../../assets/ssencial-logo.svg"
          height="30px"
          class="mt-2"
          alt="ssencial"
        />
      </router-link>
      <img
        v-else
        src="../../assets/ssencial-logo.svg"
        height="30px"
        class="mt-2"
        alt="ssencial"
      />
    </div>
    <v-spacer></v-spacer>
    <v-menu
      bottom
      max-width="250px"
      min-width="200px"
      rounded
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-avatar size="35">
            <v-img v-if="data.photoURL" :src="data.photoURL" alt="user" />
            <v-icon v-else size="35">account_circle</v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center pa-0">
          <v-container class="pa-5 py-4">
            <div class="mx-auto text-center">
              <v-badge
                dot
                overlap
                :color="isOnline ? 'success' : 'error'"
                offset-y="8"
                offset-x="8"
              >
                <v-avatar size="50">
                  <v-img v-if="data.photoURL" :src="data.photoURL" alt="user">
                    <template v-slot:placeholder>
                      <v-row class="ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-icon v-else size="50">account_circle</v-icon>
                </v-avatar>
              </v-badge>
              <h3 class="mt-2">{{ data.displayName }}</h3>
              <p class="caption mt-1 mb-1">
                {{ data.email }}
              </p>
              <div class="mt-1 mb-1">
                <v-chip class="ma-0" small v-if="getCurrentBranchOffice">
                  <v-icon size="13" class="mr-1">store</v-icon
                  >{{ getCurrentBranchOffice.branch_office_name }}
                </v-chip>
              </div>

              <v-divider class="my-2"></v-divider>
              <v-btn
                small
                block
                color="primary"
                @click="$router.push({ name: 'settings' })"
                v-if="!bussinessInfo"
              >
                Ajustes
              </v-btn>
              <v-btn small color="error" @click="signOut()" v-else>
                <v-icon>exit_to_app</v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { toggleNavegationDrawer } from "@/services/DrawerBus";

export default {
  computed: {
    ...mapGetters("user", ["isOnline"]),
    ...mapState("user", ["data", "bussinessInfo"]),
    ...mapGetters("business", ["getCurrentBranchOffice"])
  },
  methods: {
    ...mapActions("user", ["signOut"]),
    toogleDrawer() {
      toggleNavegationDrawer();
    }
  }
};
</script>

<style scoped>
.absolute-branch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
