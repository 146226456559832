<template>
  <v-snackbar
    v-model="snackbar"
    :color="snackbarColor"
    :top="true"
    :timeout="snackbarTimeout"
    elevation="20"
  >
    {{ snackbarMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import EventBus from "@/config/bus";

export default {
  data() {
    return {
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      snackbarTimeout: 5000
    };
  },
  mounted() {
    EventBus.$on("SNACKBAR_SHOW", ({ message, color, timeout }) => {
      this.snackbar = true;
      this.snackbarMessage = message;
      this.snackbarColor = color;
      if (timeout) this.snackbarTimeout = timeout;
    });
    EventBus.$on("SNACKBAR_HIDE", () => {
      this.snackbar = false;
      this.snackbarMessage = "";
      this.snackbarColor = "";
      this.snackbarTimeout = 5000;
    });
  },
  methods: {
    snackbarHide() {
      EventBus.$emit("SNACKBAR_HIDE");
    }
  }
};
</script>
