// :: Modules
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

// :: State
const state = {
  headerTitle: "",
  // :: Image
  imageData: {
    imageSrc: null,
    blob: null
  },
  loadImage: false,
  // :: Barcode
  barcodeResult: null,
  // :: Search Barcode
  searchBarcodeResult: null,
  // :: Save changes
  changeDetection: false,
  // analytics branchOffice
  currentBranchSelected: "",
  // Mexico States
  states: [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila de Zaragoza",
    "Colima",
    "Durango",
    "Guanajuato",
    "Guerrero",
    "Estado de Hidalgo",
    "Jalisco",
    "Estado de México",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas"
  ]
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
