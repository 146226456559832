export default () => {
  return {
    data: {},
    userInfoData: {},
    userStatus: false,
    logged: false,
    bussinessInfo: false, // :: Check business info is filled
    // :: User Components
    components: {
      welcome: true
    },
    // :: Loaders
    loginLoader: false
  };
};
