export default {
  isLogged: ({ logged }) => logged,
  isAdminUser: ({ data }) =>
    data.customClaims ? data.customClaims.admin : false,
  isOnline: ({ userStatus }) => userStatus,
  isOwner: ({ data }) => (data.customClaims ? data.customClaims.owner : false),
  isPayment: ({ data }) => (data.customClaims ? data.customClaims.paid : false),
  businessID: ({ data }) => data.business_id,
  currentUid: ({ data }) => data.uid
};
