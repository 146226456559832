import { errorMessage } from "@/services/MessageService";
import { functions } from "@/config/firebase";
import {
  loaderDone,
  loaderStart,
  showMessageLoader
} from "@/services/LoaderService";

export default {
  async registerUser(_, data) {
    loaderStart();
    try {
      showMessageLoader("Creando el usuario por favor espera");
      const createUser = await functions.httpsCallable("createUser");
      const userID = await createUser(data);
      loaderDone();
      return userID.data;
    } catch (error) {
      loaderDone();
      errorMessage({
        message: error,
        timeout: 10000
      });
    }
  },
  async updateUserData(_, data) {
    loaderStart();
    try {
      showMessageLoader("Realizando los ultimos cambios");
      const updateUserData = await functions.httpsCallable("updateUser");
      await updateUserData(data);
      loaderDone();
    } catch (error) {
      loaderDone();
      errorMessage({
        message: error
      });
    }
  }
};
