import defaultState from "./state";

export default {
  userSave(state, data) {
    state.data = {
      email: data.user.email,
      emailVerified: data.user.emailVerified,
      displayName: data.user.displayName,
      phoneNumber: data.user.phoneNumber,
      photoURL: data.user.photoURL,
      providerData: data.user.providerData,
      uid: data.user.uid,
      customClaims: data.customClaims,
      business_id: data.customClaims.business_id
    };
    state.logged = true;
  },
  userInfoData(state, data) {
    state.userInfoData = data;
  },
  userStatus(state, status) {
    state.userStatus = status;
  },
  userLogout(state) {
    state.data = {};
    state.logged = false;
  },
  setBussinessStatus(state, value) {
    state.bussinessInfo = value;
  },
  setComponentsUser(state, components) {
    state.components = components;
  },
  setLoginLoader(state, status) {
    state.loginLoader = status;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  }
};
