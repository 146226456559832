import defaultState from "./state";

export default {
  setAllBranchOffices(state, branchOfficesData) {
    state.branchOffice = branchOfficesData;
  },
  setCurrentBranch(state, branchID) {
    state.currentBranchID = branchID;
  },
  setBusinessData(state, business) {
    state.businessData = business;
  },
  setBusinessMetadataPlan(state, data) {
    state.businessMetadaPlan = data;
  },
  setBusinessMetadataState(state, data) {
    state.businessMetadaState = data;
  },
  setBusinessDatabase(state, data) {
    state.businessDatabase = data;
  },
  setLatLng(state, location) {
    state.businessLocationLatLng = location;
  },
  setFormBusinessSetup(state, data) {
    const { key, value } = data;
    state.businessSetupForm[key] = value;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  }
};
