import { firestore, database, functions } from "@/config/firebase";
import { errorMessage, showMessage } from "@/services/MessageService";
import {
  loaderDone,
  loaderStart,
  showMessageLoader
} from "@/services/LoaderService";
import moment from "moment";

export default {
  /**
   * Get all branch offices of business database callection and sabes in store all branch office and set new current branch
   * @param {object} Store
   */
  async getBranchOfficeData({ commit, dispatch, state, rootState }) {
    try {
      const businessID = rootState.user.data.business_id;
      const branchOffices = await firestore("business")
        .doc(businessID)
        .collection("branch_offices")
        .get();
      const branchOfficesData = branchOffices.docs.map(doc => doc.data());
      commit("setAllBranchOffices", branchOfficesData);
      if (state.currentBranchID)
        dispatch("changeCurrentBranch", state.currentBranchID);
      else dispatch("changeCurrentBranch", branchOfficesData[0].id);
    } catch {
      errorMessage({
        message: "No se pudo obtener la información de las sucursales."
      });
    }
  },
  /**
   * Ger data of single branch office
   * @param {object} Store
   * @param {string} branchID branch office id
   * @returns {object} branch office data
   */
  async getSingleBranchOfficeData({ rootState }, branchID) {
    try {
      const businessID = rootState.user.data.business_id;
      const branchOffice = await firestore("business")
        .doc(businessID)
        .collection("branch_offices")
        .doc(branchID)
        .get();
      return branchOffice.data();
    } catch (error) {
      errorMessage({
        message:
          "No se pudo obtener la información de la sucursal, Error" + error
      });
    }
  },
  /**
   * Register branch office in
   * @param {object} Store
   * @param {object} data branch office data
   * @returns void
   */
  async registerBranchOffice({ state }, data) {
    loaderStart();
    const createdLog = moment().unix();
    try {
      const branchOfficeRegister = await functions.httpsCallable(
        "branchOfficeRegister"
      );
      showMessageLoader("Creando sucursal, espera un momento");
      const branchRequest = await branchOfficeRegister(data);
      const branchRequestData = branchRequest.data;
      if (branchRequestData.message == "max-limit") {
        errorMessage({
          message:
            "LLegaste al limite de registro de sucursales, si quieres añadir más sucursales contactanos y te las habilitaremos"
        });
        return;
      }
      showMessageLoader(
        "Actualizando productos en la sucursal, esto puede tomar un tiempo si tienes muchos productos"
      );
      database(`store`).once("value", async snapshot => {
        if (snapshot.exists()) {
          const updates = {};
          snapshot.forEach(productSnap => {
            const p = productSnap.val();
            let branchOfficeMetadata = {
              name: data.branch_office_name,
              metadata: {
                last_updated: createdLog
              }
            };
            if (p.branch_office[state.currentBranchID].inventory) {
              branchOfficeMetadata.inventory = {
                min_inventory:
                  p.branch_office[state.currentBranchID].inventory
                    .min_inventory,
                total_inventory: 0
              };
            }
            updates[
              `/store/${p.product_id}/branch_office/${branchRequestData.id}`
            ] = branchOfficeMetadata;
          });
          showMessageLoader("Estamos realizando los ultimos ajustes");
          await database().update(updates);
          loaderDone();
          return;
        } else {
          loaderDone();
          return false;
        }
      });
    } catch {
      errorMessage({
        message:
          "No se pudo registrar la sucursal intenta de nuevo o contacta con soporte."
      });
    }
  },
  async getBusinessDatabase({ commit }, businessID) {
    try {
      const branchOfficeDatabase = await firestore("business")
        .doc(businessID)
        .collection("metadata")
        .doc("database")
        .get();
      commit("setBusinessDatabase", branchOfficeDatabase.data());
      return branchOfficeDatabase.data();
    } catch (error) {
      errorMessage({
        message: "No fue posible obtener los datos del negocio (Database)"
      });
    }
  },
  /**
   * Set new id to branchOfficeID
   * @param {vuex} param0
   * @param {string} branchOfficeID Current ID from branch office
   */
  async changeCurrentBranch({ commit }, branchOfficeID) {
    commit("setCurrentBranch", branchOfficeID);
  },
  async getPortalLinkCustomer() {
    loaderStart();
    try {
      showMessageLoader("Espere un momento en lo que se generan tus facturas");
      const functionRef = functions.httpsCallable("createPortalLink");
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);
      loaderDone();
    } catch (error) {
      errorMessage({
        message: "No fue posible obtener las facturas, " + error.message
      });
      loaderDone();
    }
  },
  async setFirstBusinessData({ dispatch, rootState }, data) {
    try {
      if (rootState.app.imageData.blob) {
        const imageURL = await await dispatch(
          "app/uploadImageProfileToStorage",
          rootState.app.imageData.blob,
          { root: true }
        );
        data.photoURL = imageURL;
        await dispatch("app/resetImageData", null, { root: true });
      }
      const businessData = {
        name: data.business_name,
        mail: data.business_mail,
        marketplace_namespace: data.marketplace_namespace,
        marketplace_url: `https://marketplace.ssencial.com/store/${data.marketplace_namespace}`,
        categorie: data.categorie,
        phone: data.phone_branch_office,
        location: data.location,
        business_hours: data.business_hours,
        business_hours_data: data.business_hours_data,
        status: "ACTIVE"
      };
      const user = {
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        whatsapp: data.whatsapp,
        password: data.password,
        photoURL: data.photoURL || null
      };
      const branchOfficeData = {
        branch_office_name: data.branch_office_name,
        location: data.location,
        status: "ACTIVE"
      };
      const plan = data.plan;
      const businessSetup = await functions.httpsCallable(
        "businessSetupByCustomer"
      );
      const response = await businessSetup({
        businessData,
        branchOfficeData,
        plan,
        user
      });
      return response;
    } catch (error) {
      errorMessage({
        message:
          "No se pudo registrar correctamente la información proporcionada, intenlo de nuevo."
      });
    }
  },
  async getBusinessData({ commit, rootState }) {
    try {
      const businessID = rootState.user.data.business_id;
      const businessData = await firestore("business")
        .doc(businessID)
        .get();
      await commit("setBusinessData", businessData.data());
      return businessData.data();
    } catch (error) {
      errorMessage({
        message:
          "No se pudo obtener la información del negocio, Error: " + error.code
      });
    }
  },
  async updateBusinessData({ rootState, state }, data) {
    try {
      loaderStart();
      const businessID = rootState.user.data.business_id;
      await firestore("business")
        .doc(businessID)
        .update(data);
      if (data.location) {
        await firestore("business")
          .doc(businessID)
          .collection("branch_offices")
          .doc(state.businessData.primary_branch_office)
          .update({
            location: data.location
          });
      }
      loaderDone();
      showMessage({
        message: "Se actualizo correctamente la información del negocio",
        color: "success"
      });
    } catch (error) {
      errorMessage({
        message:
          "No se pudo obtener la información del negocio, Error: " + error.code
      });
    }
  },
  async updateBranchOfficeData({ rootState, state }, data) {
    loaderStart();
    try {
      const businessID = rootState.user.data.business_id;
      const branchId = data.id;
      delete data.id;
      await firestore("business")
        .doc(businessID)
        .collection("branch_offices")
        .doc(branchId)
        .update(data);
      if (state.businessData.primary_branch_office === branchId) {
        if (data.location) {
          await firestore("business")
            .doc(businessID)
            .update({ location: data.location });
        }
      }
      showMessage({
        message: "Se actualizo correctamente la información de la sucursal",
        color: "success"
      });
      loaderDone();
      return true;
    } catch (error) {
      loaderDone();
      errorMessage({
        message:
          "No se pudo obtener la información de la sucursal, Error" + error
      });
    }
  },
  resetState({ commit }) {
    commit("resetState");
  },
  // :: Busines plan
  async getBusinessPlan({ commit, rootState }) {
    try {
      const businessID = rootState.user.data.business_id;
      const data = await firestore(`business/${businessID}/metadata`)
        .doc("plan")
        .get();
      commit("setBusinessMetadataPlan", data.data());
      return data.data();
    } catch (error) {
      errorMessage({
        message:
          "No se pudo obtener la información del negocio, Error (plan): " +
          error.code
      });
    }
  },
  async getPlanData(_, product_id) {
    try {
      const data = await firestore("products")
        .doc(product_id)
        .get();
      return data.data();
    } catch (error) {
      errorMessage({
        message:
          "No se pudo obtener la información del negocio, Error (plan): " +
          error.code
      });
    }
  },
  // :: Busines state
  async getBusinessState({ commit, rootState }) {
    try {
      const businessID = rootState.user.data.business_id;
      const data = await firestore(`business/${businessID}/metadata`)
        .doc("state")
        .get();
      commit("setBusinessMetadataState", data.data());
      return data.data();
    } catch (error) {
      errorMessage({
        message:
          "No se pudo obtener la información del negocio, Error (plan): " +
          error.code
      });
    }
  },
  async changeFormBusinessData({ commit }, data) {
    commit("setFormBusinessSetup", data);
  },
  // Location
  async setLocationLatLng({ commit }, location) {
    commit("setLatLng", location);
  }
};
