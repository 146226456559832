import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

// :: MODULES
import user from "./user";
import app from "./app";
import pointSale from "./pointSale";
import business from "./business";
import products from "./products";
import settings from "./settings";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  supportCircular: true,
  storage: window.localStorage,
  // :: Modules that need persistence
  modules: ["user", "pointSale", "business", "settings", "products"]
});

const store = new Vuex.Store({
  modules: {
    user,
    app,
    pointSale,
    business,
    products,
    settings
  },
  plugins: [vuexLocal.plugin]
});

export default store;
