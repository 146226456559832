// :: MessageService
// :: Emits the `SNACKBAR_SHOW` event through a global bus
import EventBus from "@/config/bus";
import { analytics } from "@/config/firebase";

/**
 * Receives an object containing a message and a color for the message and emits the `SNACKBAR_SHOW` message
 * @param {String} message - Snackbar message, Ej: "Error in notification"
 * @param {String} color - Snackbar color, Ej: "error"
 * @param {Number} timeout - Snackbar time, EJ: 10000
 */
export const showMessage = ({ message, color, timeout }) => {
  EventBus.$emit("SNACKBAR_SHOW", { message, color, timeout });
};

/**
 * Receives an error message, used for google analytics
 * @param {String} message - Snackbar message, Ej: "Error in notification"
 * @param {Number} timeout - Snackbar time, EJ: 10000
 */
export const errorMessage = ({ message, timeout }) => {
  analytics.logEvent("custom_error_message", {
    message: message
  });

  return showMessage({ message, color: "error", timeout });
};
