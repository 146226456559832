// :: LoaderService
// :: Emits the loader message event through a global bus
import EventBus from "@/config/bus";

// :: loaderDone
// :: Emits the `PROGRESS_HIDE` message
export const loaderDone = () => EventBus.$emit("PROGRESS_HIDE");

// :: loaderStart
// :: Emits the `PROGRESS_SHOW` message
export const loaderStart = () => EventBus.$emit("PROGRESS_SHOW");

// :: showMessage
// :: Emits the `MESSAGE_SHOW` message
export const showMessageLoader = message =>
  EventBus.$emit("MESSAGE_SHOW", message);
