import defaultState from "./state";

export default {
  setCategories(state, categories) {
    state.categories = categories;
  },
  setCategorySelected(state, category) {
    state.currentCategory = category;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  }
};
