<template>
  <v-navigation-drawer
    app
    v-model="openOrCloseDrawer"
    width="250"
    :height="$vuetify.breakpoint.height + 'px'"
    color="background"
    clipped
    :permanent="$vuetify.breakpoint.lgAndUp"
  >
    <template v-slot:prepend>
      <v-list class="pb-0 pt-1" color="background">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold text-subtitle-1 ma-0">
              <v-progress-circular
                indeterminate
                v-if="loaderBusinessData"
              ></v-progress-circular>
              <span v-if="!loaderBusinessData" class="text-h5">{{
                getBusiness.name
              }}</span>
            </v-list-item-title>
            <div>
              <p class="ma-0 caption text-truncate" v-if="!loaderBusinessData">
                <v-icon size="13" class="mr-1">location_on</v-icon
                >{{ getBusiness.location | locationFormat }}
              </p>
              <v-chip
                to="/ajustes/sucursales"
                class="ma-0"
                small
                v-if="getCurrentBranchOffice"
              >
                <v-icon size="13" class="mr-1">store</v-icon
                >{{ getCurrentBranchOffice.branch_office_name }}
              </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>

    <v-list>
      <template v-for="(item, index) in items">
        <v-list-item
          :key="item.title"
          link
          color="primary"
          :to="item.to"
          v-if="item.user"
        >
          <v-list-item-action class="mr-6">
            <v-icon color="light_dark">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-body-1">
            <v-list-item-title link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          color="light_black"
          v-else-if="item.children"
          :key="item.title"
          v-model="item.model"
        >
          <template v-slot:prependIcon>
            <v-icon color="light_dark" style="">
              {{ item.icon }}
            </v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.to"
            link
            class="pl-12"
          >
            <v-list-item-action class="mr-2" v-if="child.icon">
              <v-icon color="light_dark">{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider
          :key="index"
          v-else-if="item.divider && item.admin"
          class="my-2"
        ></v-divider>
        <v-list-item
          :key="item.title"
          link
          color="primary"
          :to="item.to"
          v-else-if="item.admin"
        >
          <v-list-item-action class="mr-6">
            <v-icon color="light_dark">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-body-1">
            <v-list-item-title link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="index + 'last'"
          v-else-if="item.divider && item.last"
          class="my-2"
        ></v-divider>
        <v-list-item
          :key="item.title"
          link
          color="primary"
          :to="item.to"
          v-else-if="item.last"
        >
          <v-list-item-action class="mr-6">
            <v-icon color="light_dark">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-body-1">
            <v-list-item-title link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        link
        color="primary"
        target="_blank"
        href="https://ayuda.ssencial.com"
      >
        <v-list-item-action class="mr-6">
          <v-icon color="light_dark">support</v-icon>
        </v-list-item-action>
        <v-list-item-content class="text-body-1">
          <v-list-item-title link>
            Ayuda
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-footer class="caption" color="background">
        <v-container class="py-1">
          <div class="d-flex justify-center">
            <router-link to="/">
              <img
                v-if="$vuetify.theme.dark"
                src="../../assets/ssencial-logo-white.svg"
                height="23px"
                alt="ssencial"
                class="mt-2"
              />
              <img
                v-else
                src="../../assets/ssencial-logo.svg"
                height="23px"
                class="mt-2"
                alt="ssencial"
              />
            </router-link>
          </div>
          <div class="text-center">
            Made with ❤️ by
            <a
              href="http://ssencial.com"
              target="_blank"
              class="ml-1 text-decoration-none"
              >SSENCIAL</a
            >
          </div>
        </v-container>
      </v-footer>
    </template>
  </v-navigation-drawer>
</template>

<script>
import EventBus from "@/config/bus";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    // :: data
    selectedItem: null,
    openOrCloseDrawer: false,
    // :: Loaders
    loaderBusinessData: true
  }),
  computed: {
    ...mapGetters("business", ["getBusiness", "getCurrentBranchOffice"]),
    ...mapGetters("user", ["isAdminUser"]),
    items() {
      const listData = [
        { title: "Dashboard", icon: "dashboard", to: "/", user: true },
        {
          title: "Punto de venta",
          icon: "point_of_sale",
          to: "/punto-venta",
          user: true
        },
        {
          title: "Productos",
          icon: "category",
          model: false,
          children: [
            { title: "Inventario", icon: "storage", to: "/inventario" },
            { title: "Registro", icon: "add_box", to: "/registro-producto" },
            { title: "Stock", icon: "auto_awesome_motion", to: "/stock" }
          ]
        },
        { title: "Ordenes", icon: "request_page", to: "/ordenes", user: true },
        {
          title: "Sitio Web",
          icon: "web",
          to: "/sitio-web",
          user: true
        },
        { divider: true, admin: this.isAdminUser },
        {
          title: "Analytics",
          icon: "analytics",
          to: "/estadisticas",
          admin: this.isAdminUser
        },
        {
          title: "Ventas",
          icon: "insights",
          to: "/ventas",
          admin: this.isAdminUser
        },
        {
          title: "Usuarios",
          icon: "assignment_ind",
          to: "/usuarios",
          admin: this.isAdminUser
        },
        { divider: true, last: true },
        {
          title: "Ajustes",
          icon: "settings_applications",
          to: "/ajustes",
          last: true
        }
      ];
      return listData;
    }
  },
  mounted() {
    this.getBusinessInfo();
    EventBus.$on("TOGGLE_DRAWER", () => {
      this.openOrCloseDrawer = !this.openOrCloseDrawer;
    });
  },
  methods: {
    ...mapActions("business", ["getBusinessData"]),
    navigateTo(path) {
      // :: Close drawer in mobile an tablet
      if (!this.$vuetify.breakpoint.lgAndUp) {
        this.openOrCloseDrawer = !this.openOrCloseDrawer;
      }
      // :: Go to view
      if (path != "logout" && this.$router.currentRoute.name != path) {
        this.$router.push({ name: path });
      }
    },
    async getBusinessInfo() {
      this.loaderBusinessData = true;
      await this.getBusinessData();
      this.loaderBusinessData = false;
    }
  },
  filters: {
    locationFormat(location) {
      if (location) {
        return `${location.street_name}, ${location.city_name}, ${location.state_name}, México`;
      }
      return "";
    }
  }
};
</script>

<style></style>
