const idTimestamp = new Date().valueOf();

export default () => {
  return {
    footerOption: 0,
    // :: List component
    productList: [],
    loaderProducts: false,
    currentProductSelected: {},
    filters: {
      showOnlyBy: [0],
      active: false
    },
    // :: Button status sale
    showSellProduct: false,
    // :: Customers
    stateCounterCustomers: 1,
    customersList: [
      {
        id: idTimestamp,
        name: "Ticket " + idTimestamp,
        totalProducts: 0,
        totalCostPrice: 0,
        totalToPay: 0,
        subtotalPrice: 0,
        discount: 0,
        shoppingCartProductList: []
      }
    ],
    actualCustomerID: idTimestamp,
    actualCustomerIndex: 0,
    // :: Shoping Cart
    currentEditProduct: {},
    paymentMethod: 0,
    lastOrder: null,
    // :: App Components
    currentDetailProduct: {}
  };
};
