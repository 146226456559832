// :: globals
// :: Define all global variables inside this folder
module.exports = {
  // :: Vuetify theme
  theme: {
    themes: {
      dark: {
        primary: "#006CFF",
        secondary: "#455A64",
        extra: "#00B398",
        accent: "#FAEA3C",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        card_background: "#2B2B2B",
        light_black: "#FFFFFF"
      },
      light: {
        primary: "#006CFF",
        secondary: "#455A64",
        extra: "#00B398",
        accent: "#FAEA3C",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#F5F5FB",
        card_background: "#FFFFFF",
        light_black: "#000000"
      }
    }
  },
  // :: Vuetify theme
  components: {
    header: {
      background: "#455A64",
      dark: true
    }
  },
  // :: App
  app: {
    name: process.env.VUE_APP_NAME,
    url: process.env.VUE_APP_URL,
    stripe_key: process.env.VUE_APP_STRIPE_KEY,
    version: process.env.VUE_APP_VERSION,
    maps_api_key: process.env.VUE_APP_MAPS_API_KEY
  }
};
