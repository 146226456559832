export default {
  resetRegisterProductState(state) {
    state.imageData = {
      imageSrc: null,
      blob: null
    };
    state.loadImage = false;
    state.barcodeResult = null;
  },
  resetImageData(state) {
    state.imageData = {
      imageSrc: null,
      blob: null
    };
  },
  setChangesDetected(state, status) {
    state.changeDetection = status;
  },
  setCurrentBranchOfficeSelected(state, branchOffice) {
    state.currentBranchSelected = branchOffice;
  }
};
