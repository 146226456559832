<template>
  <!-- Sizes your content based upon application components -->
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid class="py-0">
      <v-slide-x-reverse-transition
        hide-on-leave
        mode="out-in"
        :duration="{ enter: 200, leave: 200 }"
      >
        <!-- vue-router render views -->
        <router-view></router-view>
      </v-slide-x-reverse-transition>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "AppView"
};
</script>

<style></style>
