import Vue from "vue";
import App from "./App.vue";
import "./config/registerServiceWorker";
import router from "./config/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

// :: Change local lenguage
moment.locale("es");

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
