import * as firebaseModule from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/database";
import "firebase/analytics";

// :: Environment Variables
const {
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_DATABASE_URL,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_STORAGE_BUCKET,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_FIREBASE_MEASUREMENT_ID
} = process.env;

// :: Firebase configurations
// :: Client side ENV variables https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
const config = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DATABASE_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: VUE_APP_FIREBASE_APP_ID,
  measurementId: VUE_APP_FIREBASE_MEASUREMENT_ID
};
firebaseModule.initializeApp(config);
let customeDatabaseApp = null;
// :: Create multiple database instance
export async function databaseInstance(databaseURL, user) {
  const config = {
    apiKey: VUE_APP_FIREBASE_API_KEY,
    authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: databaseURL,
    projectId: VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: VUE_APP_FIREBASE_APP_ID,
    measurementId: VUE_APP_FIREBASE_MEASUREMENT_ID
  };
  if (databaseURL) {
    customeDatabaseApp = firebaseModule.initializeApp(
      config,
      "customer-database-app"
    );
    if (user) {
      await customeDatabaseApp
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);
    }
  }
}
// :: Variable to initialize Firebase
export const firebase = firebaseModule;

// :: Firebase Storage reference
export const storage = firebase.storage().ref();

// :: Firestore and Realtime Databases

// Firestore reference
export const firestoreDb = firebase.firestore();
// Firebase persistence in firestore
firestoreDb.enablePersistence();
// Returns a reference for a collection of Firestore
export const firestore = ref => firestoreDb.collection(ref);
// Returns a reference for the Realtime database
export const databaseDefault = ref => firebase.database().ref(ref);
export const database = ref => {
  if (customeDatabaseApp) return firebase.database(customeDatabaseApp).ref(ref);
  else return firebase.database().ref(ref);
};
// :: Timestamp server firestore & RealTime DB
export const timestampFirestore = firebase.firestore.FieldValue.serverTimestamp();
export const timestampDatabase = firebase.database.ServerValue.TIMESTAMP;

// :: Firebase Auth references
export const auth = firebase.auth();
export const firebaseAuth = firebase.auth;

// :: Firebase functions references
export const functions = firebase.functions();

// :: Firebase Analytics
export const analytics = firebase.analytics();

// :: Delete initialize App
export async function deleteInitializeApp() {
  if (customeDatabaseApp) {
    await customeDatabaseApp.delete();
    customeDatabaseApp = null;
  }
}
