export default () => {
  return {
    businessData: null,
    branchOffice: [{}],
    currentBranchID: null,
    businessMetadaPlan: null,
    businessMetadaState: null,
    businessDatabase: null,
    businessLocationLatLng: null,
    businessSetupForm: {
      firstName: null,
      lastName: null,
      phone: "",
      whatsapp: null,
      password: "",
      businessName: null,
      businessMail: null,
      categorieSelected: null,
      branchOfficeName: null,
      marketplaceURL: null,
      streetNumber: null,
      street: null,
      city: null,
      stateSelected: null,
      postalCode: null,
      streetReference: null,
      phoneBranchOffice: "",
      daysSelected: [],
      openTime: null,
      closeTime: null,
      plan: null,
      days: [
        { day: "monday", letter: "L", value: 0 },
        { day: "tuesday", letter: "M", value: 1 },
        { day: "wednesday", letter: "M", value: 2 },
        { day: "thursday", letter: "J", value: 3 },
        { day: "friday", letter: "V", value: 4 },
        { day: "saturday", letter: "S", value: 5 },
        { day: "sunday", letter: "D", value: 6 }
      ],
      full_address: null,
      map_point: null
    }
  };
};
