export default {
  getBusiness: ({ businessData }) => businessData,
  getBusinessUsers: ({ businessData }) => businessData.users,
  getBranchOffices: ({ branchOffice }) => branchOffice,
  getCurrentBranchOffice: ({ currentBranchID, branchOffice }) =>
    branchOffice.find(branch => branch.id === currentBranchID),
  getBusinessMetadaPlan: ({ businessMetadaPlan }) => businessMetadaPlan,
  getBusinessPlanID: ({ businessMetadaPlan }) =>
    businessMetadaPlan ? businessMetadaPlan.id : null,
  getBusinessDatabaseURL: ({ businessDatabase }) =>
    businessDatabase ? businessDatabase.databaseUrl : null
};
