// :: Modules
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

// :: State
const state = {
  userTheme: false
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
