import { errorMessage, showMessage } from "@/services/MessageService";
import { database, storage, databaseDefault } from "@/config/firebase";
import {
  loaderDone,
  loaderStart,
  showMessageLoader
} from "@/services/LoaderService";
import router from "@/config/router";

export default {
  /**
   * function to upload information and image of a product
   * @param {vuex} param0 Call dispatch function for upload image
   * @param {Object} data Data info about product to upload
   */
  async uploadProduct({ dispatch, rootState }, product) {
    const {
      data,
      totalInventory,
      currentBranch,
      totalMoneyPrice,
      totalMoney,
      isInventoried
    } = product;
    loaderStart();
    showMessageLoader("Subiendo tu producto, espera unos segundos");
    const databasePath = `store/${data.product_id}`;
    const databasePathMetadata =
      "analytics/store/inventory/total_register_products";
    let businessMetadaState = null;
    if (!rootState.business.businessMetadaPlan)
      businessMetadaState = await dispatch("business/getBusinessPlan", null, {
        root: true
      });
    else businessMetadaState = rootState.business.businessMetadaPlan;
    try {
      if (data.product_id) {
        const productRef = database(databasePath);
        const metaProductsInventoried = await database(
          databasePathMetadata
        ).once("value");
        if (metaProductsInventoried.val() < businessMetadaState.products) {
          const productShallowRef = await database(
            `store_shallow/${data.product_id}`
          );
          const productData = await productRef.once("value");
          if (productData.exists()) {
            loaderDone();
            showMessage({
              message:
                "El producto que intentas registrar ya ha sido registrado",
              color: "warning"
            });
          } else {
            if (data.photoData) {
              showMessageLoader("Realizando las últimas modificaciones");
              const photoURL = await dispatch(
                "app/uploadProductImageToStorage",
                { blob: data.photoData, productID: data.product_id },
                { root: true }
              );
              data.photoURL = photoURL;
              delete data.photoData;
            }
            if (isInventoried) {
              dispatch("updateAnalyticsInventory", {
                totalInventory,
                totalMoneyPrice,
                totalMoney,
                currentBranch
              });
            }
            showMessageLoader("Casi terminamos...");
            await productShallowRef.set(true);
            await productRef.set(data);

            database(databasePathMetadata).transaction(actualTotalProducts => {
              actualTotalProducts = actualTotalProducts || 0;
              actualTotalProducts++;
              return actualTotalProducts;
            });
            showMessage({
              message:
                "Se creo el producto " + data.product_id + " exitosamente.",
              color: "success"
            });
            loaderDone();
          }
        } else {
          showMessage({
            message: "No es posible registrar el producto (límite excedido)",
            timeout: 3000,
            color: "warning"
          });
          router.push({
            name: "noMoreProducts"
          });
          loaderDone();
        }
      } else {
        errorMessage({
          message: "Rectifique la sucursal"
        });
      }
    } catch (error) {
      loaderDone();
      errorMessage({
        message: "No fue posible subir tu producto, " + error.code
      });
    }
  },
  async enableInventoryProduct({ dispatch }, data) {
    loaderStart();
    showMessageLoader("Actualizando datos del producto");
    try {
      const {
        productID,
        branchOfficeInventory,
        totalInventory,
        totalMoney,
        totalMoneyPrice,
        currentBranch
      } = data;
      const databasePath = `store/${productID}/branch_office`;
      await database(databasePath).update(branchOfficeInventory);
      showMessageLoader("Actualizando analiticos");
      dispatch("updateAnalyticsInventory", {
        totalInventory: totalInventory,
        totalMoney: totalMoney,
        totalMoneyPrice: totalMoneyPrice,
        currentBranch
      });
      showMessage({
        message:
          "Se habilito el inventario del producto " +
          data.product_id +
          " exitosamente.",
        color: "success"
      });
      loaderDone();
    } catch (error) {
      loaderDone();
      errorMessage({
        message:
          "No fue posible habilitar el inventario en el producto: " +
          data.product_id +
          " Error: " +
          error.code
      });
    }
  },
  async editProduct({ rootState, dispatch }, data) {
    loaderStart();
    showMessageLoader("Actualizando datos, espera un momento");
    const currentBranch = rootState.business.currentBranchID;
    try {
      const databasePath = `store/${data.productData.product_id}`;
      if (data.photoData) {
        const photoURL = await dispatch(
          "app/uploadProductImageToStorage",
          { blob: data.photoData, productID: data.productData.product_id },
          { root: true }
        );
        data.productData.photoURL = photoURL;
      }
      if (data.productTotalVariance) {
        showMessageLoader("Actualizando analiticos");
        dispatch("updateAnalyticsInventory", {
          totalInventory: data.productTotalVariance,
          totalMoney: data.productTotalMoney,
          totalMoneyPrice: data.productTotalMoneyPrice,
          currentBranch
        });
      }
      await database(databasePath).update(data.productData);
      showMessage({
        message:
          "Se actualizo el producto " + data.product_id + " exitosamente.",
        color: "success"
      });
      dispatch("changeStateTabItem", 0);
      loaderDone();
    } catch (error) {
      loaderDone();
      errorMessage({
        message:
          "No fue posible actualizar el producto: " +
          data.product_id +
          " Error: " +
          error.code
      });
    }
  },
  /**
   * Update Analytics inventory of branch office and global inventory
   * @param {*} _ No Store params
   * @param {Object} inventoryData { totalInventory, totalMoney, totalMoneyPrice, currentBranch }
   */
  updateAnalyticsInventory(_, inventoryData) {
    const {
      totalInventory,
      totalMoney,
      totalMoneyPrice,
      currentBranch
    } = inventoryData;
    const total_products = parseInt(totalInventory);
    const total_money_cost =
      Math.round((totalMoney + Number.EPSILON) * 100) / 100;
    const total_money =
      Math.round((totalMoneyPrice + Number.EPSILON) * 100) / 100;
    try {
      const databaseInventoryPath = `branch_offices/${currentBranch}`;
      // :: Update local Store statistics with transaction
      database(
        `${databaseInventoryPath}/analytics/store/inventory/total_products`
      ).transaction(actualTotalProducts => {
        actualTotalProducts = actualTotalProducts || 0;
        actualTotalProducts += total_products;
        return actualTotalProducts;
      });
      database(
        `${databaseInventoryPath}/analytics/store/inventory/total_money`
      ).transaction(actualTotalMoney => {
        actualTotalMoney = actualTotalMoney || 0;
        actualTotalMoney += total_money;
        return Math.round((actualTotalMoney + Number.EPSILON) * 100) / 100;
      });
      database(
        `${databaseInventoryPath}/analytics/store/inventory/total_money_cost`
      ).transaction(actualTotalMoneyCost => {
        actualTotalMoneyCost = actualTotalMoneyCost || 0;
        actualTotalMoneyCost += total_money_cost;
        return Math.round((actualTotalMoneyCost + Number.EPSILON) * 100) / 100;
      });
      // :: Update Global Store statistics with transaction
      database("analytics/store/inventory/total_products").transaction(
        actualTotalProducts => {
          actualTotalProducts = actualTotalProducts || 0;
          actualTotalProducts += total_products;
          return actualTotalProducts;
        }
      );
      database("analytics/store/inventory/total_money").transaction(
        actualTotalMoney => {
          actualTotalMoney = actualTotalMoney || 0;
          actualTotalMoney += total_money;
          return Math.round((actualTotalMoney + Number.EPSILON) * 100) / 100;
        }
      );
      database("analytics/store/inventory/total_money_cost").transaction(
        actualTotalMoneyCost => {
          actualTotalMoneyCost = actualTotalMoneyCost || 0;
          actualTotalMoneyCost += total_money_cost;
          return (
            Math.round((actualTotalMoneyCost + Number.EPSILON) * 100) / 100
          );
        }
      );
    } catch (error) {
      errorMessage({
        message:
          "Se tuvo un problema con la transacción al actualizar los analiticos" +
          error.code
      });
    }
  },
  async updateProductStock({ rootState, dispatch }, data) {
    loaderStart();
    try {
      const {
        product_id,
        total_to_add,
        total_money_to_add,
        total_money_to_add_price
      } = data;
      showMessageLoader("Actualizando inventario");
      const currentBranch = rootState.business.currentBranchID;
      database(
        `store/${product_id}/branch_office/${currentBranch}/inventory/total_inventory`
      ).transaction(actualInventory => {
        actualInventory = actualInventory || 0;
        actualInventory += total_to_add;
        return actualInventory;
      });
      showMessageLoader("Actualizando analiticos");
      dispatch("updateAnalyticsInventory", {
        totalInventory: total_to_add,
        totalMoney: total_money_to_add,
        totalMoneyPrice: total_money_to_add_price,
        currentBranch
      });
      showMessage({
        message:
          "Se actualizo el inventario de " + data.product_id + " exitosamente.",
        color: "success"
      });
      loaderDone();
    } catch (error) {
      loaderDone();
      errorMessage({
        message:
          "No fue posible actualizar el inventario del producto: " +
          data.product_id +
          " Error: " +
          error.code
      });
    }
  },
  async removeProduct({ rootState, dispatch }, data) {
    const { product_id, totalProducts, totalMoney, totalMoneyPrice } = data;
    loaderStart();
    try {
      showMessageLoader("Eliminando productos");
      const businessID = rootState.user.data.business_id;
      const currentBranch = rootState.business.currentBranchID;
      if (data.photo) {
        await storage
          .child(`business/${businessID}/products/${product_id}/A`)
          .delete();
      }
      await database(`store/${product_id}`).remove();
      await database(`store_shallow/${product_id}`).remove();
      showMessageLoader("Actualizando analiticos");
      await dispatch("updateAnalyticsInventory", {
        totalInventory: -Math.abs(totalProducts),
        totalMoney: -Math.abs(totalMoney),
        totalMoneyPrice: -Math.abs(totalMoneyPrice),
        currentBranch
      });
      database("analytics/store/inventory/total_register_products").transaction(
        actualTotalProducts => {
          actualTotalProducts = actualTotalProducts || 0;
          if (actualTotalProducts > 0) actualTotalProducts--;
          return actualTotalProducts;
        }
      );
      showMessage({
        message: "Se borro el producto " + product_id + " exitosamente.",
        color: "success",
        timeout: 2500
      });
      router.push({
        name: "inventory"
      });
      loaderDone();
    } catch (error) {
      loaderDone();
      errorMessage({
        message:
          "No fue posible borrar el producto: " +
          product_id +
          " Error: " +
          error.code
      });
    }
  },
  changeStateTabItem({ state }, section) {
    state.tabSection = section;
  },
  async getCategories({ commit }) {
    const categories = await databaseDefault("categories").once("value");
    commit("setCategories", categories.val());
  },
  async changeCategorySelected({ commit }, categorie) {
    commit("setCategorySelected", categorie);
  },
  resetState({ commit }) {
    commit("resetState");
  }
};
// :: Check if image is in storage
// async function checkImageInStorage(data) {
//   const photoRef = await storage.child(
//     `${data.rootRef}/${data.path}/${data.photoName}`
//   );
//   try {
//     const photoURL = await photoRef.getDownloadURL();
//     return photoURL;
//   } catch (error) {
//     switch (error.code) {
//       case "storage/object-not-found": {
//         let newPhotURL = await uploadImageToStorage(data);
//         return newPhotURL;
//       }
//     }
//   }
// }
