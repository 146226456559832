<template>
  <v-overlay :value="progress" color="black" class="text-center">
    <v-slide-y-transition>
      <p class="pa-6 ma-0 text-h6" v-if="message != undefined">{{ message }}</p>
    </v-slide-y-transition>
    <v-progress-circular indeterminate size="60"></v-progress-circular>
  </v-overlay>
</template>
<script>
import EventBus from "@/config/bus";

export default {
  data() {
    return {
      progress: false,
      error: false,
      message: null
    };
  },
  mounted() {
    EventBus.$on("PROGRESS_SHOW", () => {
      this.progress = true;
      // :: Hide after 6 seconds, in case the request fails
      setTimeout(function() {
        this.hideProgress;
      }, 6000);
    });
    EventBus.$on("MESSAGE_SHOW", message => {
      this.message = message;
    });
    EventBus.$on("PROGRESS_HIDE", () => {
      this.progress = false;
    });
  },
  methods: {
    hideProgress() {
      EventBus.$emit("PROGRESS_HIDE");
      this.error = false;
    },
    reloadPage() {
      this.$router.go();
    }
  }
};
</script>
