import defaultState from "./state";

export default {
  setOptionFooterNavegation(state, option) {
    state.footerOption = option;
  },
  // :: Set Firebase Products
  setProducList(state, data) {
    const productsList = data.data.sort(sortListByName);
    const productData = productsList.map(function(el) {
      const product = Object.assign({}, el);
      if (product.branch_office[data.currentBranchID].inventory) {
        product.total_inventory = parseInt(
          product.branch_office[data.currentBranchID].inventory.total_inventory
        );
        product.min_inventory = parseInt(
          product.branch_office[data.currentBranchID].inventory.min_inventory
        );
      }
      return product;
    });
    state.productList = productData;
    state.loaderProducts = false;
  },
  // :: Buttom total payment
  changeStatusShowProduct(state, status) {
    state.showSellProduct = status;
  },
  // :: List Mutations
  setCurrentProductSelected(state, product) {
    state.currentProductSelected = product;
  },
  // :: Products List
  saveProductInCart(state, data) {
    if (
      typeof data.price_by_unit === "number" &&
      typeof data.total_price === "number" &&
      typeof data.total_products === "number"
    ) {
      const customerID = state.actualCustomerIndex;
      // :: Check if product it's register
      const productIndex = state.customersList[
        customerID
      ].shoppingCartProductList.findIndex(
        productSaved => productSaved.id === data.id
      );
      // :: Update product data
      if (productIndex >= 0) {
        let total_products =
          state.customersList[customerID].shoppingCartProductList[productIndex]
            .total_products + data.total_products;
        let total_price =
          state.customersList[customerID].shoppingCartProductList[productIndex]
            .price_by_unit * total_products;
        let total_cost_price =
          state.customersList[customerID].shoppingCartProductList[productIndex]
            .extraMetadata.cost_price * total_products;
        let subtotal_price =
          state.customersList[customerID].shoppingCartProductList[productIndex]
            .price_by_unit * total_products;

        // :: Check if the quantity of items has a discount
        if (data.wholesale) {
          const wholesaleInfo = {
            data: data.extraMetadata.wholesales,
            actualData: {
              minimum_products: total_products,
              wholesale_price: data.price_by_unit
            }
          };
          const wholesaleData = getWhosaleData(wholesaleInfo);
          const whosalePrice = wholesaleData.wholesale_price;
          const minimunProducts = wholesaleData.minimum_products;
          if (total_products >= minimunProducts) {
            total_price = whosalePrice * total_products;
          }
          state.customersList[customerID].shoppingCartProductList[
            productIndex
          ].wholesale = wholesaleData;
        }
        // :: update product data
        state.customersList[customerID].shoppingCartProductList[
          productIndex
        ].total_products = total_products;
        state.customersList[customerID].shoppingCartProductList[
          productIndex
        ].total_cost_price = total_cost_price;
        state.customersList[customerID].shoppingCartProductList[
          productIndex
        ].total_price = total_price;
        state.customersList[customerID].shoppingCartProductList[
          productIndex
        ].subtotal_price = subtotal_price;
      }
      // :: Add new product
      else {
        state.customersList[customerID].shoppingCartProductList.push(data);
      }
    }
  },
  setTotalPriceAndTotalProducts(state) {
    const customerID = state.actualCustomerIndex;
    const products = state.customersList[customerID].shoppingCartProductList;
    const totalPrice = products.reduce(
      (sum, { total_price }) => sum + total_price,
      0
    );
    const totalProducts = products.reduce(
      (sum, { total_products }) => sum + total_products,
      0
    );
    const totalCostPrice = products.reduce((sum, { total_cost_price }) => {
      return sum + total_cost_price;
    }, 0);
    const subtotalPrice = products.reduce(
      (sum, { subtotal_price }) => sum + subtotal_price,
      0
    );
    const discount = subtotalPrice - totalPrice;
    state.customersList[customerID].subtotalPrice = subtotalPrice;
    state.customersList[customerID].totalToPay = totalPrice;
    state.customersList[customerID].totalProducts = totalProducts;
    state.customersList[customerID].totalCostPrice = totalCostPrice;
    state.customersList[customerID].discount = discount;
  },
  // :: Customer
  setCurrentCustomerID(state, id) {
    state.actualCustomerID = id;
  },
  setCurrentCustomerIndexPosition(state, index) {
    state.actualCustomerIndex = index;
  },
  setNewCustomer(state, customer) {
    state.customersList.push(customer);
  },
  deleteCustomer(state, index) {
    state.customersList.splice(index, 1);
  },
  resetCustomer(state, index) {
    const ticketID = new Date().valueOf();
    const payload = {
      id: ticketID,
      name: `Ticket ${ticketID}`,
      totalProducts: 0,
      totalToPay: 0,
      subtotalPrice: 0,
      totalCostPrice: 0,
      discount: 0,
      shoppingCartProductList: []
    };
    state.customersList.splice(index, 1, payload);
  },
  clearAllCustomers(state) {
    state.customersList = [];
  },
  addNewCustomerCounter(state) {
    state.stateCounterCustomers += 1;
  },
  // :: Cart
  updateProductCart(state, data) {
    const customerID = state.actualCustomerIndex;
    const productIndex = data.index;
    let total_products = data.totalInventory;
    let total_price = data.totalPrice;
    let subtotal_price = data.totalPrice;
    const costPriceProduct =
      state.customersList[customerID].shoppingCartProductList[productIndex]
        .extraMetadata.cost_price;
    // :: Check if the quantity of items has wholesale
    if (
      state.customersList[customerID].shoppingCartProductList[productIndex]
        .wholesale
    ) {
      const priceByUnit =
        state.customersList[customerID].shoppingCartProductList[productIndex]
          .price_by_unit;
      const wholesaleInfo = {
        data:
          state.customersList[customerID].shoppingCartProductList[productIndex]
            .extraMetadata.wholesales,
        actualData: {
          minimum_products: total_products,
          wholesale_price: priceByUnit
        }
      };
      const wholesaleData = getWhosaleData(wholesaleInfo);
      const whosalePrice = wholesaleData.wholesale_price;
      const minimunProducts = wholesaleData.minimum_products;
      if (total_products >= minimunProducts) {
        total_price = whosalePrice * total_products;
      }
      state.customersList[customerID].shoppingCartProductList[
        productIndex
      ].wholesale = wholesaleData;
    }
    // :: update product data
    state.customersList[customerID].shoppingCartProductList[
      productIndex
    ].total_products = total_products;
    state.customersList[customerID].shoppingCartProductList[
      productIndex
    ].total_cost_price = total_products * costPriceProduct;
    state.customersList[customerID].shoppingCartProductList[
      productIndex
    ].total_price = total_price;
    state.customersList[customerID].shoppingCartProductList[
      productIndex
    ].subtotal_price = subtotal_price;
  },
  deleteProductCart(state, index) {
    const customerID = state.actualCustomerIndex;
    state.customersList[customerID].shoppingCartProductList.splice(index, 1);
  },
  setPaymentMethod(state, value) {
    state.paymentMethod = value;
  },
  setLastOrder(state, data) {
    state.lastOrder = data;
  },
  setShowOnlyBy(state, data) {
    state.filters.showOnlyBy = data.show;
    state.filters.active = data.active;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  }
};

function sortListByName(a, b) {
  if (a.name_product < b.name_product) return -1;
  if (a.name_product > b.name_product) return 1;
  return 0;
}
function getWhosaleData(wholesalesData) {
  const wholesales = wholesalesData.data;
  const items = wholesalesData.actualData.minimum_products;
  let whosaleDiscount = {
    wholesale_price: wholesalesData.actualData.wholesale_price,
    minimum_products: wholesalesData.actualData.minimum_products
  };
  for (let i = 0; i < wholesales.length; i++) {
    const wholesale = wholesales[i];
    if (items >= wholesale.minimum_products) {
      whosaleDiscount.wholesale_price = parseFloat(wholesale.wholesale_price);
      whosaleDiscount.minimum_products = parseInt(wholesale.minimum_products);
    }
  }
  return whosaleDiscount;
}
