import { showMessage, errorMessage } from "@/services/MessageService";
import { database } from "@/config/firebase";
import {
  loaderDone,
  loaderStart,
  showMessageLoader
} from "@/services/LoaderService";
import moment from "moment";

export default {
  // :: Save Firebase Products in state
  saveProductsList({ commit, rootState }, data) {
    const branchOfficeID = rootState.business.currentBranchID;
    commit("setProducList", { data: data, currentBranchID: branchOfficeID });
  },
  activateLoaderProductList({ state }, status) {
    state.loaderProducts = status;
  },
  // :: List Actions
  showCurrentProductSelected({ commit }, product) {
    commit("setCurrentProductSelected", product);
  },
  async addProductToCart({ commit, dispatch }, product) {
    // :: Cart actions
    const data = {
      name_product: product.data.name_product,
      price_by_unit: parseFloat(product.data.price),
      total_products: parseInt(product.items),
      total_cost_price: parseFloat(product.data.cost_price),
      id: product.data.product_id,
      extraMetadata: {
        barcode: product.data.barcode,
        branch_office_id: product.data.branch_office_id,
        cost_price: product.data.cost_price,
        description: product.data.description
      }
    };
    if (product.data.photoURL) data.photoURL = product.data.photoURL;
    if (product.data.total_inventory != null)
      data.extraMetadata.total_inventory = product.data.total_inventory;
    if (product.data.wholesale)
      data.extraMetadata.wholesales = product.data.wholesale;
    // :: Pre filled Total and Subtotal price
    const totalPriceWithutWhosale =
      parseFloat(product.data.price) * parseInt(product.items);
    data.subtotal_price = totalPriceWithutWhosale;
    data.total_price = totalPriceWithutWhosale;
    // :: Check the wholesale is active
    if (product.data.wholesale) {
      const wholesaleInfo = {
        data: product.data.wholesale,
        actualData: {
          minimum_products: product.items,
          wholesale_price: totalPriceWithutWhosale
        }
      };
      const wholesaleData = await dispatch("getWhosaleData", wholesaleInfo);
      const whosalePrice = wholesaleData.wholesale_price;
      const minimunProducts = wholesaleData.minimum_products;

      data.wholesale = {
        wholesale_price: whosalePrice,
        minimum_products: minimunProducts
      };
      // :: Check if the quantity of items has a discount
      if (data.total_products >= minimunProducts) {
        data.total_price = parseFloat(whosalePrice) * parseInt(product.items);
      }
    }
    commit("saveProductInCart", data);
    commit("setTotalPriceAndTotalProducts");
  },
  viewDetailProduct({ state }, product) {
    state.currentDetailProduct = product;
  },
  // :: General Botton status
  showSellAlert({ commit }, status) {
    commit("changeStatusShowProduct", status);
  },

  // :: Cart
  updatetProductInCart({ commit }, data) {
    commit("updateProductCart", data);
    commit("setTotalPriceAndTotalProducts");
  },
  viewEditProductInCart({ state }, data) {
    state.currentEditProduct = {
      ...data.product,
      indexProduct: data.indexProduct
    };
  },
  deleteProductInCart({ commit }, index) {
    commit("deleteProductCart", index);
    commit("setTotalPriceAndTotalProducts");
  },
  // :: Customer
  changeCurrenCustomerID({ commit, state }, index) {
    const customerCurrentID = state.customersList[index].id;
    commit("setCurrentCustomerID", customerCurrentID);
    commit("setCurrentCustomerIndexPosition", index);
    showMessage({
      message: "Haz cambiado de Ticket correctamente",
      timeout: 1000,
      color: "success"
    });
  },
  addCustomer({ state, commit, getters }) {
    const lengthCustomer = state.customersList.length;
    if (lengthCustomer <= 20) {
      commit("addNewCustomerCounter");
      const ticketID = new Date().valueOf();
      const customer = {
        id: ticketID,
        name: `Ticket ${ticketID}`,
        totalProducts: 0,
        totalToPay: 0,
        totalCostPrice: 0,
        subtotalPrice: 0,
        discount: 0,
        shoppingCartProductList: []
      };
      commit("setNewCustomer", customer);
      commit("setCurrentCustomerID", customer.id);
      const customerIndex = getters.getCustomerPositionIndex;
      commit("setCurrentCustomerIndexPosition", customerIndex);
    } else {
      showMessage({
        message:
          "No fue posible agregar más Tickets ya que tienes muchos Tickets listadas, elimina los que no necesitas para poder agregar nuevas.",
        color: "error"
      });
    }
  },
  async resetAllCustomers({ commit, dispatch }) {
    await commit("clearAllCustomers");
    await dispatch("addCustomer");
  },
  async resetCustomer({ state, commit }, customerIndex) {
    await commit("resetCustomer", customerIndex);
    const customerCurrentID = state.customersList[customerIndex].id;
    commit("setCurrentCustomerID", customerCurrentID);
  },
  async deleteCustomer({ state, dispatch, commit, getters }, id) {
    const customerIndex = state.customersList.findIndex(
      customer => customer.id === id
    );
    if (state.customersList.length === 1) {
      await commit("deleteCustomer", customerIndex);
      await dispatch("addCustomer");
    } else {
      await commit("deleteCustomer", customerIndex);
    }
    const lastPosition = state.customersList.length - 1;
    const customerCurrentID = state.customersList[lastPosition].id;
    commit("setCurrentCustomerID", customerCurrentID);
    const customerCurrentIndex = getters.getCustomerPositionIndex;
    commit("setCurrentCustomerIndexPosition", customerCurrentIndex);
  },
  // :: Firebase actions
  async saveSaleInDatabase({ state, rootState, dispatch, commit }) {
    loaderStart();
    showMessageLoader("Creando venta");
    try {
      // :: data declaration
      let updates = {};
      const branchOfficeID = rootState.business.currentBranchID;
      const monthSale = await dispatch("getActualMonthID");
      const businessRef = `branch_offices/${branchOfficeID}`;
      const customerPointSaleData =
        state.customersList[state.actualCustomerIndex];
      const newSaleKey = customerPointSaleData.id;
      const customerData = await dispatch(
        "refactorCustomerData",
        customerPointSaleData
      );
      const totalPriceCostSale =
        Math.round(
          (customerPointSaleData.totalCostPrice + Number.EPSILON) * 100
        ) / 100;
      const customerAnalyticsData = {
        created: customerData.created,
        id: customerData.id,
        total_products: customerData.total_products,
        total_sold: customerData.total_sold,
        total_cost_price: totalPriceCostSale,
        payment_method: customerData.payment_method
      };
      updates[`sales/${newSaleKey}`] = customerData;
      // :: Month analytics
      updates[
        `${businessRef}/analytics/sales/${monthSale}/sales_list/${newSaleKey}`
      ] = customerAnalyticsData;
      // :: Today analytics
      updates[
        `${businessRef}/analytics/sales/today/sales_list/${newSaleKey}`
      ] = customerAnalyticsData;
      await database().update(updates); // :: Update all data
      // :: Update Analytics Month statistics with transaction
      showMessageLoader("Actualizando datos");
      database(
        `${businessRef}/analytics/sales/${monthSale}/total_sold_price`
      ).transaction(actualTotalSold => {
        actualTotalSold = actualTotalSold || 0;
        actualTotalSold += totalPriceCostSale;
        return actualTotalSold;
      });
      database(
        `${businessRef}/analytics/sales/${monthSale}/total_sold`
      ).transaction(actualTotalSold => {
        actualTotalSold = actualTotalSold || 0;
        actualTotalSold += customerData.total_sold;
        return actualTotalSold;
      });
      database(
        `${businessRef}/analytics/sales/${monthSale}/total_products`
      ).transaction(actualTotalProducts => {
        actualTotalProducts = actualTotalProducts || 0;
        actualTotalProducts += customerData.total_products;
        return actualTotalProducts;
      });
      database(
        `${businessRef}/analytics/sales/${monthSale}/total_orders`
      ).transaction(actualTotalOrders => {
        actualTotalOrders = actualTotalOrders || 0;
        actualTotalOrders++;
        return actualTotalOrders;
      });
      // :: Update Analytics Today statistics with transaction
      database(`${businessRef}/analytics/sales/today/total_sold`).transaction(
        actualTotalSold => {
          actualTotalSold = actualTotalSold || 0;
          actualTotalSold += customerData.total_sold;
          return actualTotalSold;
        }
      );
      database(
        `${businessRef}/analytics/sales/today/total_sold_price`
      ).transaction(actualTotalSold => {
        actualTotalSold = actualTotalSold || 0;
        actualTotalSold += totalPriceCostSale;
        return actualTotalSold;
      });
      database(
        `${businessRef}/analytics/sales/today/total_products`
      ).transaction(actualTotalProducts => {
        actualTotalProducts = actualTotalProducts || 0;
        actualTotalProducts += customerData.total_products;
        return actualTotalProducts;
      });
      database(`${businessRef}/analytics/sales/today/total_orders`).transaction(
        actualTotalOrders => {
          actualTotalOrders = actualTotalOrders || 0;
          actualTotalOrders++;
          return actualTotalOrders;
        }
      );
      // :: Update inventory data
      const {
        totalProductsUpdated,
        totalPriceUpdated,
        totalPriceCostUpdated
      } = await dispatch("updateProductsInventory", customerData.product_list);

      // :: Update Local & global Store INVENTORY statistics with transaction
      if (totalProductsUpdated > 0) {
        await dispatch(
          "products/updateAnalyticsInventory",
          {
            totalInventory: -Math.abs(totalProductsUpdated),
            totalMoney: -Math.abs(totalPriceCostUpdated),
            totalMoneyPrice: -Math.abs(totalPriceUpdated),
            currentBranch: branchOfficeID
          },
          { root: true }
        );
      }
      commit("setLastOrder", customerAnalyticsData);
      dispatch("changeOptionFooterNavegation", 0); // :: Go to list
      dispatch("resetCustomer", state.actualCustomerIndex);
      showMessage({
        message: "Venta realiza exitosamente",
        color: "success"
      });
      loaderDone();
    } catch (error) {
      errorMessage({
        message:
          "Error al realizar la venta, si presentas inconvenientes contacta a soporte con el siguiente código: " +
          error
      });
      loaderDone();
    }
  },
  async updateProductsInventory({ rootState }, products) {
    let totalProducts = 0;
    let totalPrice = 0;
    let totalPriceCost = 0;
    const branchOfficeID = rootState.business.currentBranchID;
    try {
      for (let index = 0; index < products.length; index++) {
        const product = products[index];
        await database(
          `store/${product.id}/branch_office/${branchOfficeID}/inventory/total_inventory`
        ).transaction(actualTotalInventory => {
          if (actualTotalInventory) {
            actualTotalInventory = actualTotalInventory || 0;
            if (actualTotalInventory > 0) {
              const totalWithRest =
                actualTotalInventory - product.total_products;
              if (totalWithRest < 0) {
                totalProducts += actualTotalInventory;
                totalPrice += product.price_by_unit * actualTotalInventory;
                totalPriceCost += product.cost_price * actualTotalInventory;
                return 0;
              }
              const priceCost = product.total_products * product.cost_price;
              totalProducts += product.total_products;
              totalPrice += product.total_price;
              totalPriceCost += priceCost;
              actualTotalInventory -= product.total_products;
              return actualTotalInventory;
            }
            return 0;
          }
          return;
        });
      }
      return {
        totalProductsUpdated: totalProducts,
        totalPriceUpdated: totalPrice,
        totalPriceCostUpdated: totalPriceCost
      };
    } catch (error) {
      errorMessage({
        message: "Error al actualizar el inventario, Error " + error.code
      });
    }
  },
  // :: Get curren month ID
  getActualMonthID() {
    const actualMonth = parseInt(moment().month()) + 1;
    const actualMonthTransform = `${actualMonth}${moment().year()}`;
    return actualMonthTransform;
  },
  // :: refactor data for order
  refactorCustomerData({ state, rootState }, customerData) {
    const branchOfficeID = rootState.business.currentBranchID;
    const productListData = customerData.shoppingCartProductList.map(
      customerProductList => {
        const dataProduct = {
          id: customerProductList.id,
          name_product: customerProductList.name_product,
          description: customerProductList.extraMetadata.description,
          price_by_unit: customerProductList.price_by_unit,
          cost_price: customerProductList.extraMetadata.cost_price,
          subtotal_price: customerProductList.subtotal_price,
          total_price: customerProductList.total_price,
          total_products: customerProductList.total_products
        };
        if (customerProductList.photoURL)
          dataProduct.photoURL = customerProductList.photoURL;
        if (customerProductList.wholesale) {
          if (
            customerProductList.wholesale.wholesale_price <
            customerProductList.price_by_unit
          ) {
            dataProduct.discount = true;
            dataProduct.wholesale = customerProductList.wholesale;
          }
        }
        return dataProduct;
      }
    );
    const data = {
      discount: customerData.discount,
      id: customerData.id,
      branch_office: branchOfficeID,
      name: customerData.name,
      subtotal_price: customerData.subtotalPrice,
      total_products: customerData.totalProducts,
      total_sold: customerData.totalToPay,
      product_list: productListData,
      created: moment().unix(),
      payment_method: state.paymentMethod,
      sold_by: {
        uid: rootState.user.data.uid,
        name: rootState.user.data.displayName
      }
    };
    return data;
  },
  // :: Get the information for the calculation of the wholesale sale
  getWhosaleData(_, wholesalesData) {
    const wholesales = wholesalesData.data;
    const items = wholesalesData.actualData.minimum_products;
    let whosaleDiscount = {
      wholesale_price: wholesalesData.actualData.wholesale_price,
      minimum_products: wholesalesData.actualData.minimum_products
    };
    for (let i = 0; i < wholesales.length; i++) {
      const wholesale = wholesales[i];
      if (items >= wholesale.minimum_products) {
        whosaleDiscount.wholesale_price = parseFloat(wholesale.wholesale_price);
        whosaleDiscount.minimum_products = parseInt(wholesale.minimum_products);
      }
    }
    return whosaleDiscount;
  },
  changeOptionFooterNavegation({ commit }, option) {
    commit("setOptionFooterNavegation", option);
  },
  setPaymentMethod({ commit }, value) {
    commit("setPaymentMethod", value);
  },
  filterList({ state, commit }, filters) {
    loaderStart();
    try {
      // :: Sort by
      if (filters.typeSort === 0) {
        state.productList.sort((a, b) =>
          a[filters.sortBy] < b[filters.sortBy]
            ? 1
            : b[filters.sortBy] < a[filters.sortBy]
            ? -1
            : 0
        );
      } else {
        state.productList.sort((a, b) =>
          a[filters.sortBy] > b[filters.sortBy]
            ? 1
            : b[filters.sortBy] > a[filters.sortBy]
            ? -1
            : 0
        );
      }
      if (
        filters.sortBy === "name_product" &&
        filters.typeSort === 0 &&
        filters.show.includes(0)
      ) {
        commit("setShowOnlyBy", {
          show: filters.show,
          active: false
        });
      } else {
        commit("setShowOnlyBy", {
          show: filters.show,
          active: true
        });
      }
      loaderDone();
    } catch (error) {
      showMessage({
        message: "Error al aplicar el filtro, " + error,
        color: "error"
      });
    }
  },
  resetState({ commit }) {
    commit("resetState");
  }
};
