import imageCompression from "browser-image-compression";
import { errorMessage } from "@/services/MessageService";
import { storage } from "@/config/firebase";
import {
  loaderDone,
  showMessageLoader,
  loaderStart
} from "@/services/LoaderService";

export default {
  /**
   * Compression image in browser
   * @param {Event} event - input file event
   * Doc: https://www.npmjs.com/package/browser-image-compression#usage
   */
  async handleImageCompression({ state }, event) {
    if (event.target.files.length > 0) {
      state.loadImage = true;
      const imageFile = event.target.files[0];
      const options = {
        maxSizeMB: 0.015,
        maxWidthOrHeight: 720
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        const downloadLink = URL.createObjectURL(compressedFile);
        state.imageData.imageSrc = downloadLink;
        state.imageData.blob = compressedFile;
        state.loadImage = false;
      } catch {
        errorMessage({
          message: "No se pudo cargar la imagen correctamente"
        });
      }
    }
  },
  /**
   * Updload image to cloud storage
   * @param {*} _ No actions in store
   * @param {object} data {url,blob} object image
   * @returns {string} Image URL
   */
  async uploadImageToStorage(_, data) {
    try {
      loaderStart();
      showMessageLoader("subiendo imagen");
      const imageRef = await storage.child(data.url);
      await imageRef.put(data.blob);
      const imageURL = await imageRef.getDownloadURL();
      return imageURL;
    } catch {
      loaderDone();
      errorMessage({
        message: "Ocurrio un error al subir tu image, intentalo nuevamente."
      });
    }
  },
  /**
   * Upload product images of storage and return public URL
   * @param {object} store
   * @param {object} data {productID, blob} image data
   * @returns {string} Image URL
   */
  async uploadProductImageToStorage({ rootState }, data) {
    const businessID = rootState.user.data.business_id;
    const imageRef = await storage.child(
      `business/${businessID}/products/${data.productID}/A`
    );
    try {
      await imageRef.put(data.blob);
      const imageURL = await imageRef.getDownloadURL();
      return imageURL;
    } catch (error) {
      loaderDone();
      errorMessage({
        message: "No fue posible subir tu image, intentalo nuevamente."
      });
    }
  },
  /**
   * Upload profile image to cloud storage
   * @param {object} store
   * @param {any} blob Blob image
   * @returns {string} Image URL
   */
  async uploadImageProfileToStorage({ rootState }, blob) {
    try {
      const uid = rootState.user.data.uid;
      const businessID = rootState.user.data.business_id;
      const imageRef = await storage.child(
        `business/${businessID}/users/profile_photos/${uid}`
      );
      await imageRef.put(blob);
      const imageURL = await imageRef.getDownloadURL();
      return imageURL;
    } catch {
      loaderDone();
      errorMessage({
        message: "No fue posible subir tu image, intentalo nuevamente."
      });
    }
  },
  // :: Others actions
  /**
   * Save barcode resul in store
   * @param {object} Store
   * @param {string} result Decoder result
   */
  resultDecoder({ state }, result) {
    state.barcodeResult = result;
  },
  resetRegisterProduct({ commit }) {
    commit("resetRegisterProductState");
  },
  resetImageData({ commit }) {
    commit("resetImageData");
  },
  statusChangesDetected({ commit }, status) {
    commit("setChangesDetected", status);
  },
  changeSelectedBranchOffice({ commit }, branchOffice) {
    commit("setCurrentBranchOfficeSelected", branchOffice);
  },
  resultSearchShallowProduct({ state }, result) {
    state.searchBarcodeResult = result;
  }
};
