import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from "@/store";
// :: Views
const Home = () => import("@/views/Home");
const PointSale = () => import("@/views/PointSale");
const Login = () => import("@/views/Login");
const NotFound = () => import("@/views/404.vue");
const RecoverPassword = () => import("@/views/RecoverPassword");
const Register = () => import("@/views/Register");
const Orders = () => import("@/views/orders/Orders.vue");
const OrderDetail = () => import("@/views/orders/Detail.vue");
const OrderEdit = () => import("@/views/orders/Edit.vue");
const Customers = () => import("@/views/Customers/Customers");
const CustomersDetail = () => import("@/views/Customers/CustomerDetail");
const Inventory = () => import("@/views/Products/Inventory");
const Product = () => import("@/views/Products/Product");
const NoMoreProducts = () => import("@/views/Products/NoMoreProducts");
const Analytics = () => import("@/views/Analytics");
const Users = () => import("@/views/Users/Users");
const UserRegister = () => import("@/views/Users/UserRegister");
const Settings = () => import("@/views/settings/Settings");
const AppManager = () => import("@/views/settings/AppManager");
const BusinessSetup = () => import("@/views/BusinessSetup");
const PaymentStatus = () => import("@/views/PaymentStatus");
const PaymentSuccessful = () => import("@/views/PaymentSuccessful");
const Discounts = () => import("@/views/Discounts/Discounts");
const DiscountRegister = () => import("@/views/Discounts/DiscountRegister");
const WebSite = () => import("@/views/WebSite");
const StockSearch = () => import("@/views/Products/StockSearch");
const DiscountAutoRegister = () =>
  import("@/views/Discounts/DiscountAutoRegister");
// :: Settings
const BusinessSettings = () => import("@/views/settings/general/Business");
const BranchOfficeSettings = () =>
  import("@/views/settings/branch-offices/BranchOffices");
const BillingAndPlan = () => import("@/views/settings/BillingAndPlan");
const Account = () => import("@/views/settings/Account");
const Sales = () => import("@/views/Sales");

// :: Settings A
const BranchOfficeDetailSettings = () =>
  import("../views/settings/branch-offices/Detail.vue");
const BranchOfficeRegisterSettings = () =>
  import("../views/settings/branch-offices/Register.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/punto-venta",
    name: "sale",
    component: PointSale,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const userPaymentStatus = store.getters["user/isPayment"];
      if (userPaymentStatus) next();
      else next("/payment-status");
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/registro-producto",
    name: "register",
    component: Register,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const userPaymentStatus = store.getters["user/isPayment"];
      if (userPaymentStatus) next();
      else next("/payment-status");
    }
  },
  {
    path: "/ordenes",
    name: "orders",
    component: Orders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orden/:orderID",
    name: "orderDetail",
    component: OrderDetail,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/orden/:orderID/editar",
    name: "orderEdit",
    component: OrderEdit,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/inventario",
    name: "inventory",
    component: Inventory,
    meta: {
      requiresAuth: true
    },
    props: route => ({
      sectionOption: parseInt(route.params.sectionOption)
    })
  },
  {
    path: "/inventario/:productID",
    name: "product",
    component: Product,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/stock",
    name: "stockSearch",
    component: StockSearch,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/upgrade-products",
    name: "noMoreProducts",
    component: NoMoreProducts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/clientes",
    name: "customers",
    component: Customers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sitio-web",
    name: "webSite",
    component: WebSite,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/estadisticas",
    name: "analytics",
    component: Analytics,
    meta: {
      requiresAuth: true,
      admin: true
    },
    beforeEnter: (to, from, next) => {
      const userPaymentStatus = store.getters["user/isPayment"];
      if (userPaymentStatus) next();
      else next("/payment-status");
    }
  },
  {
    path: "/ventas",
    name: "sales",
    component: Sales,
    meta: {
      requiresAuth: true,
      admin: true
    },
    beforeEnter: (to, from, next) => {
      const userPaymentStatus = store.getters["user/isPayment"];
      if (userPaymentStatus) next();
      else next("/payment-status");
    }
  },
  {
    path: "/usuarios",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: "/usuarios/registro",
    name: "userRegister",
    component: UserRegister,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: "/ajustes",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ajustes/cuenta",
    name: "account",
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/descuentos",
    name: "discounts",
    component: Discounts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/descuentos/registro",
    name: "discountRegister",
    component: DiscountRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/descuentos/auto/registro",
    name: "discountAutoRegister",
    component: DiscountAutoRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ajustes/facturacion",
    name: "billingAndPlan",
    component: BillingAndPlan,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: "/ajustes/business",
    name: "businessSettings",
    component: BusinessSettings,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: "/ajustes/sucursales",
    name: "branchOfficeSettings",
    component: BranchOfficeSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ajustes/sucursales/:branchID/editar",
    name: "branchOfficeDetailSettings",
    component: BranchOfficeDetailSettings,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/ajustes/sucursales/registrar",
    name: "branchOfficeRegisterSettings",
    component: BranchOfficeRegisterSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/cliente/:uid",
    name: "customerDetail",
    component: CustomersDetail,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/admin/configura-tu-cuenta",
    name: "businessSetup",
    component: BusinessSetup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ajustes/app-manager",
    name: "appManager",
    component: AppManager,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/payment-status",
    name: "paymentStatus",
    component: PaymentStatus,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/payment-successful",
    name: "paymentSuccessful",
    component: PaymentSuccessful,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/recuperar-cuenta",
    name: "recoverPassword",
    component: RecoverPassword
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth"
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// :: Auth check before each route visit
router.beforeEach((to, from, next) => {
  const logged = store.getters["user/isLogged"];
  const requiresAuth = to.matched.some(({ meta }) => meta.requiresAuth);
  const requiresAdmin = to.matched.some(({ meta }) => meta.admin);
  const onlyAdminUser = store.getters["user/isAdminUser"];

  // :: Check if requires Authentication
  if (requiresAuth) {
    // :: Check if the user is not logged in (to prevent)
    if (!logged) {
      next("/login");
    } else {
      if (requiresAdmin) {
        // :: Check if the user visit admin view
        if (onlyAdminUser) {
          next();
        } else {
          next("/");
        }
      } else {
        next();
      }
    }
  } else if (to.name == "login" && logged) {
    next("/");
  } else {
    next();
  }
});

export default router;
// if(getUserAccess) {
//   next("/payment-status");
// } else{
//   next();
// }
