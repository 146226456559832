import { errorMessage } from "@/services/MessageService";

const HandlingError = error => {
  switch (error) {
    case "auth/invalid-email":
      errorMessage({
        message:
          "No hemos podido autenticarte debido a que tu correo no es válido"
      });
      break;
    case "auth/user-not-found":
      errorMessage({
        message: "Verifica tu correo ó contraseña e intentalo de nuevo"
      });
      break;
    case "auth/wrong-password":
      errorMessage({
        message: "Verifica tu correo ó contraseña e intentalo de nuevo"
      });
      break;
    case "auth/account-exists-with-different-credential":
      errorMessage({
        message:
          "No hemos podido autenticarte debido a que tu credencial ya tienen una cuenta de correo asignada"
      });
      break;
    case "auth/operation-not-allowed":
      errorMessage({
        message: "No tienes permitida la siguiente operación"
      });
      break;
    case "auth/user-disabled":
      errorMessage({
        message:
          "No hemos podido autenticarte debido a que tu cuenta está deshabilitada"
      });
      break;
    case "auth/invalid-verification-id":
      errorMessage({
        message:
          "No hemos podido autenticarte debido a que la verificación de su ID no es válido"
      });
      break;
    case "auth/auth-domain-config-required":
      errorMessage({
        message:
          "La configuración de authDomain no ha sido realizada comunicate con osoper"
      });
      break;
    case "auth/cancelled-popup-request":
      errorMessage({
        message:
          "No hemos podido autenticarte debido a que se abrieron múltiples pantallas de inicio de sesión, por favor cierrelas e intente de nuevo"
      });
      break;
    case "auth/operation-not-supported-in-this-environment":
      errorMessage({
        message:
          "No hemos podido autenticarte debido que no se cuenta con los protocolos de seguridad necesarios"
      });
      break;
    case "auth/popup-blocked":
      errorMessage({
        message:
          "No hemos podido autenticarte debido a que el navegador a bloqueado la pestaña emergente de inicio de sesión"
      });
      break;
    case "auth/popup-closed-by-user":
      errorMessage({
        message:
          "No hemos podido autenticarte debido a que has cerrado la pestaña antes de completar el proceso."
      });
      break;
    case "auth/unauthorized-domain":
      errorMessage({ message: "Dominio no autorizado para iniciar sesión" });
      break;

    case "auth/email-already-in-use":
      errorMessage({
        message:
          "Error al registrarte debido a ya existe una cuenta con el mismo correo electrónico registrado"
      });
      break;
    case "auth/weak-password":
      errorMessage({
        message:
          "Error al registrarte debido a que la contraseña es muy insegura, por favor proporciona otra"
      });
      break;
    default:
      errorMessage({
        message: `Algo inesperado fallo, código: ${error}`
      });
      break;
  }
};

export default HandlingError;
